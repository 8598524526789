import { Predicate } from "common";

export interface ElementWithRow<T> {
    element: T;
    row: number;
    top: number;
}

/**
 * Given a list of elements on timeline, lays them out in rows so that no two elements
 * overlap each other and the row number of each element is minimized.
 */
export function layoutOnTimeline<T>(elements: T[], overlaps: (l: T, r: T) => boolean, height: number, dragged?: T | null, hiddenPredicate?: Predicate<T> | null): ElementWithRow<T>[] {
    const result: Omit<ElementWithRow<T>, "top">[] = [];

    function lastElementOnRow(row: number): T | null {
        for (let i = result.length - 1; i >= 0; i--)
            if (result[i].row === row)
                return result[i].element;
        return null;
    }

    function findBestAvailableRow(newElement: T): number {
        for (let row = 0; ; row++) {
            const existingElement = lastElementOnRow(row);
            if (existingElement == null || !overlaps(existingElement, newElement))
                return row;
        }
    }

    for (const element of elements)
        result.push({element, row: findBestAvailableRow(element)});

    // Handle the dragged item specially. Make sure that dragging will never cause the amount of rows
    // to increase because it really messes up the dragging. We rather overdraw.
    if (dragged != null) {
        let row = 0;
        if (hiddenPredicate)
            row = result.find(it => hiddenPredicate(it.element))!.row;
        result.push({element: dragged, row});
    }

    // Swap rows so that row 0 means the topmost row, but the bottom row is preferred
    const maxRow = Math.max(...result.map(r => r.row));
    for (const rp of result)
        rp.row = maxRow - rp.row;

    return result.map(it => ({...it, top: it.row * height}));
}
