import { effect, Injectable, signal } from "@angular/core";
import { controlValuesSignal, ZoneId } from "common";
import { FormControl } from "@angular/forms";

@Injectable({providedIn: "root"})
export class SessionContext {

    /** Latest zone-selection made by user */
    readonly currentZone = signal<ZoneId | null>(null);

    get zoneSelection(): ZoneId | null {
        return this.currentZone();
    }

    set zoneSelection(value: ZoneId | null) {
        this.currentZone.set(value);
    }

    bindZoneFromControl(control: FormControl<ZoneId | ZoneId[] | null>): void {
        const signal = controlValuesSignal(control);
        effect(() => {
            const value = signal();
            if (!Array.isArray(value)) {
                this.zoneSelection = value;
            } else if (value.length === 1) {
                this.zoneSelection = value[0];
            }

        }, {allowSignalWrites: true});
    }
}
