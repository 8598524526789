import { Injectable, signal } from "@angular/core";
import { AccountEndpoint, CurrentUserInformation, Username } from "apina-frontend";
import { firstValueFrom } from "rxjs";
import { isNotNull, resolveZoneId, RxStompService, ZoneId } from "common";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { filter } from "rxjs/operators";

@Injectable({providedIn: "root"})
export class AccountService {

    private readonly _currentUserInfo = signal<CurrentUserInfo | null>(null);
    readonly currentUserInfo = this._currentUserInfo.asReadonly();
    readonly currentUserInfo$ = toObservable(this._currentUserInfo).pipe(filter(isNotNull));

    constructor(
        private readonly accountEndpoint: AccountEndpoint,
        rxStompService: RxStompService,
    ) {
        this.load();

        rxStompService.watch("/topic/user-settings-changed").pipe(takeUntilDestroyed()).subscribe(msg => {
            const change = JSON.parse(msg.body) as UserSettingsChangedUpdate;
            if (change.user === this._currentUserInfo()?.login)
                this.load();
        });
    }

    private async load(): Promise<void> {
        try {
            const info = await firstValueFrom(this.accountEndpoint.getCurrentUserInformation());
            this._currentUserInfo.set({
                ...info,
                defaultZone: resolveZoneId(info.defaultAreaId, info.defaultPilotageAreaId, info.defaultPilotStationId)
            });
        } catch (e) {
            console.warn("Failed to log current user", e);
        }
    }
}

export interface CurrentUserInfo extends CurrentUserInformation {
    defaultZone: ZoneId | null;
}

interface UserSettingsChangedUpdate {
    user: Username;
}
